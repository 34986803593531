import React, { useEffect, useState } from "react";
import Link from "next/link";
import { closeMblMenu } from "../redux/counterSlice";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";

const MblNavbar = ({ theme }) => {
  const { mblMenu } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const router = useRouter();
  const [navItemValue, setNavItemValue] = useState(1);
  const [navText, setnavText] = useState("");

  const handleItemDropdown = (e) => {
    if (window.innerWidth <= 1024) {
      dispatch(closeMblMenu());
    }
    const target = e.target.closest("li");

    if (!target.classList.contains("show")) {
      target.classList.add("show");
    } else {
      target.classList.remove("show");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        dispatch(closeMblMenu());
      }
    });

    if (router.asPath === "/") {
      localStorage.setItem("navItemValue", 1);
    }
    if (router.asPath === "/about") {
      localStorage.setItem("navItemValue", 2);
    }
    if (router.asPath === "/anti_counterfeit") {
      localStorage.setItem("navItemValue", 3);
    }
    // if (router.asPath === "/vacancies") {
    //   localStorage.setItem("navItemValue", 4);
    // }
    // if (router.asPath === "/licensing_rnd") {
    //   localStorage.setItem("navItemValue", 5);
    // }
    if (router.asPath.includes("products")) {
      localStorage.setItem("navItemValue", 6);
    }
    if (router.asPath.includes("item")) {
      localStorage.setItem("navItemValue", 7);
    }

    const value = localStorage.getItem("navItemValue");
    setNavItemValue(+value);

    if (navItemValue === 1) {
      setnavText("home");
    } else if (navItemValue === 2) {
      setnavText("about");
    } else if (navItemValue === 3) {
      setnavText("anti_counterfeit");
    // } else if (navItemValue === 4) {
    //   setnavText("vacancies");
    // } else if (navItemValue === 5) {
    //   setnavText("licensing_rnd");
    }else if (navItemValue > 5 && navItemValue < 8) {
      setnavText("products");
    }
  }, [dispatch, navItemValue, router]);

  return (
    <div
      className={
        mblMenu
          ? "js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent nav-menu--is-open"
          : "js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent"
      }
    >
      {/* <!-- Mobile Logo / Menu Close --> */}
      <div className="left-0 z-10 flex items-center justify-between w-full p-6 bg-white t-0 dark:bg-jacarta-800 lg:hidden">
        {/* <!-- Mobile Logo --> */}

        <Link href="/">
          <a>
            <img
              src="/images/logo.png"
              className="max-h-7 dark:hidden"
              alt="Alpha-Pharm"
            />

            <img
              src="/images/logo_white.png"
              alt="Alpha-Pharm"
              className="hidden max-h-7 dark:block"
            />
          </a>
        </Link>

        {/* <!-- Mobile Menu Close --> */}
        <button
          className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onClick={() => dispatch(closeMblMenu())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="w-4 h-4 transition-colors fill-jacarta-700 group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
          </svg>
        </button>
      </div>


      {/* <!-- Primary Nav --> */}
      <nav className="w-full navbar">
        <ul className="flex flex-col lg:flex-row">
          <li className="relative js-nav-dropdown group">
          <Link href="/">
            <button
              className={
                router.asPath === "/"
                  ? "dropdown-toggle font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:lg:text-jacarta-700 lg:text-white lg:px-5 w-full"
                  : "dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "home" ? "text-accent" : ""}>
                Home
              </span>
            </button>
            </Link>
          </li>
          <li className="relative js-nav-dropdown group">
          <Link href="/about">
            <button
              className={
                router.asPath === "/about"
                  ? "dropdown-toggle font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:lg:text-jacarta-700 lg:text-white lg:px-5 w-full"
                  : "dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "about" ? "text-accent" : ""}>
                About Us
              </span>
            </button>
            </Link>
          </li>
          <li className="relative js-nav-dropdown group">
          <Link href="/products/product_category">
            <button
              className={
                router.asPath === "/products/product_category"
                  ? "dropdown-toggle font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:lg:text-jacarta-700 lg:text-white lg:px-5 w-full"
                  : "dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "products" ? "text-accent" : ""}>
                Products
              </span>
            </button>
            </Link>
          </li>
          <li className="relative js-nav-dropdown group">
          <Link href="/anti_counterfeit">
            <button
              className={
                router.asPath === "/anti_counterfeit"
                  ? "dropdown-toggle font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:lg:text-jacarta-700 lg:text-white lg:px-5 w-full"
                  : "dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "anti_counterfeit" ? "text-accent" : ""}>
              Anti-Counterfeit
              </span>
            </button>
            </Link>
          </li>
          {/* <li className="relative js-nav-dropdown group">
          <Link href="/vacancies">
            <button
              className={
                router.asPath === "/vacancies"
                  ? "dropdown-toggle font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:lg:text-jacarta-700 lg:text-white lg:px-5 w-full"
                  : "dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "vacancies" ? "text-accent" : ""}>
               Vacancies
              </span>
            </button>
            </Link>
          </li> */}
          {/* <li className="relative js-nav-dropdown group">
          <Link href="/licensing_rnd">
            <button
              className={
                router.asPath === "/licensing_rnd"
                  ? "dropdown-toggle font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:lg:text-jacarta-700 lg:text-white lg:px-5 w-full"
                  : "dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span className={navText === "licensing_rnd" ? "text-accent" : ""}>
               In-Licensing/R&D
              </span>
            </button>
            </Link>
          </li> */}
        </ul>
      </nav>

      {/* <!-- Mobile Connect Wallet / Socials --> */}
      <div className="w-full mt-10 lg:hidden">
      </div>

      {/* <!-- Actions --> */}
      <div className="hidden ml-8 lg:flex xl:ml-12">

        {/* <!-- Dark Mode --> */}
        <button
          href="#"
          className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent js-dark-mode-trigger ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          aria-label="dark"
          onClick={theme}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="w-4 h-4 transition-colors fill-jacarta-700 dark-mode-light group-hover:fill-white group-focus:fill-white dark:hidden"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="hidden w-4 h-4 transition-colors fill-jacarta-700 dark-mode-dark group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default MblNavbar;
