import React from "react";
import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import { coverflow_data } from "../data/coverflow_data";
const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="#">
                <a className="mb-6 inline-block">
                  <img
                    src="/images/logo.png"
                    className="max-h-7 dark:hidden"
                    alt="Alpha-Pharm"
                  />
                </a>
              </Link>

              <Link href="#">
                <a className=" mb-6 inline-block">
                  <img
                    src="/images/logo_white.png"
                    className="hidden max-h-7 dark:block mb-6"
                    alt="Alpha-Pharm"
                  />
                </a>
              </Link>
              <p className="dark:text-jacarta-300 mb-12">
              Alpha-Pharm Has A Commitment To Mankind, A Commitment Of Improving The Quality Of Life For Each And Every Ind
              vidual Through Which We Constantly Strive To Innovate, Improve And Increase The Availability Of Cost-Efficient Generic Medicines To The Global Market.
              </p>
            </div>

            {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </h3>
                {single.title == "Products" ? 
                //Products Random Listing
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {coverflow_data.slice(0, 6).map((item) => {
                    const { id, img, title } = item;
                    const href = img
                    .split("/")
                    .slice(-1)
                    .toString()
                    .replace(".jpg", "")
                    .replace(".gif", "")
                    .replace("_lg", "");
                    return (
                      <li key={id}>
                        <Link href={"/item/" + href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {title}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                :         
                // Page Elements Listing        
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link href={href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>}

              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} Alpha-Pharm — Made with</span>
              <Link href="https://kikots.me/">
                <a className="hover:text-accent dark:hover:text-white">
                  {" "}
                  ❤️
                </a>
              </Link>
            </span>

            {/* <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link href="/tarms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms and conditions
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/tarms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy policy
                  </a>
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
