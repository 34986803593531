import React from "react";
import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Alpha-Pharm Healthcare",
  keyword:
    "steroids, alpha-pharma, alpha-pharm, boldebolin, healthcare, rexobol, cryptocurrency, philippines, vitagon, ampoules, astralean, cypionate, testosterone, domestic, promifen, alpha-o, doxalp, dutalp, finalp, teralp, tamalp, altamofen",
  desc: "Alpha-Pharma, our utmost commitment is improving the quality of life for each and every individual. It is through this commitment that we constantly strive to innovate, improve and increase the availability of cost-efficient generic medicines to the global market.",
};

export default Meta;
