const footerMenuList = [
  {
    id: 1,
    title: "Products",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "#",
        text: "All NFTs",
      },
      {
        id: 2,
        href: "#",
        text: "art",
      },
      {
        id: 3,
        href: "#",
        text: "music",
      },
      {
        id: 4,
        href: "#",
        text: "domain names",
      },
      {
        id: 5,
        href: "#",
        text: "collections",
      },
      {
        id: 6,
        href: "#",
        text: "virtual world",
      },
    ],
  },
  {
    id: 2,
    title: "Company",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/",
        text: "Home",
      },
      {
        id: 2,
        href: "/about",
        text: "About Us",
      },
      {
        id: 3,
        href: "/products/product_category",
        text: "Products",
      },
      {
        id: 4,
        href: "/anti_counterfeit",
        text: "Anti-Counterfeit",
      },
      // {
      //   id: 5,
      //   href: "/vacancies",
      //   text: "Vacancies",
      // },
      // {
      //   id: 6,
      //   href: "/licensing_rnd",
      //   text: "In-Licensing/R&D",
      // },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };
