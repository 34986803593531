import '../styles/globals.css';
import { ThemeProvider } from 'next-themes';
import Layout from '../components/layout';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { useRouter } from 'next/router';
// import { MetaMaskProvider } from 'metamask-react';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { useRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
function MyApp({ Component, pageProps }) {
	const router = useRouter();
	const pid = router.asPath;
	const scrollRef = useRef({
		scrollPos: 0,
	});

	return (
		<>
		    <GoogleReCaptchaProvider
             reCaptchaKey="6LcIjo4iAAAAAJY4xZXzYHzJvdfRLXhcPR0aZ_20"
             scriptProps={{
               async: false,
               defer: false,
               appendTo: "head",
               nonce: undefined,
             }}
             container={{ // optional to render inside custom element
               parameters: {
                 badge: 'bottomleft', // optional, default undefined
                 theme: 'dark', // optional, default undefined
               }
             }}
           >
			<Meta title="Home 1 || Alpha-Pharm" />

			<Provider store={store}>
				<ThemeProvider enableSystem={true} attribute="class">
				<UserContext.Provider value={{ scrollRef: scrollRef }}>
							{pid === '/login' ? (
								<Component {...pageProps} />
							) : (
								<Layout>
									<Component {...pageProps} />
								</Layout>
							)}
						</UserContext.Provider>
				</ThemeProvider>
			</Provider>
			</GoogleReCaptchaProvider>
		</>
	);
}

export default MyApp;
