export const coverflow_data = [
  {
    img: "/images/products/item_alphabol.jpg",
    title: "Alphabol",
    category: ["all", "oral"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 1,
  },
  {
    img: "/images/products/item_androxine.jpg",
    title: "Androxine",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 2,
  },
  {
    img: "/images/products/item_boldebolin.jpg",
    title: "Boldebolin",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 3,
  },
  {
    img: "/images/products/item_bulk.jpg",
    title: "BULK",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 4,
  },
  {
    img: "/images/products/item_cuts.jpg",
    title: "CUTS",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 5,
  },
  {
    img: "/images/products/item_induject-250.jpg",
    title: "Induject-250",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 6,
  },
  {
    img: "/images/products/item_mastebolin.jpg",
    title: "Mastebolin",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 7,
  },
  {
    img: "/images/products/item_nandrobolin.jpg",
    title: "Nandrobolin",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 8,
  },
  {
    img: "/images/products/item_nandrorapid.jpg",
    title: "NandroRapid",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 9,
  },
  {
    img: "/images/products/item_oxanabol.jpg",
    title: "Oxanabol",
    category: ["all", "oral"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 10,
  },
  {
    img: "/images/products/item_parabolin.jpg",
    title: "Parabolin",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 11,
  },
  {
    img: "/images/products/item_rexobol.jpg",
    title: "Rexobol",
    category: ["all", "oral"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 12,
  },
  {
    img: "/images/products/item_rexogin.jpg",
    title: "Rexogin",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 13,
  },
  {
    img: "/images/products/item_testobase.jpg",
    title: "Testobase",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 14,
  },
  {
    img: "/images/products/item_testobolin.jpg",
    title: "Testobolin",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 15,
  },
  {
    img: "/images/products/item_testocyp.jpg",
    title: "Testocyp",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 16,
  },
  {
    img: "/images/products/item_testorapid.jpg",
    title: "TestoRapid",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 17,
  },
  {
    img: "/images/products/item_trenarapid.jpg",
    title: "TrenaRapid",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 18,
  },
  {
    img: "/images/products/item_trenbolin.jpg",
    title: "Trenbolin",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 19,
  },
  {
    img: "/images/products/item_trenmix.jpg",
    title: "TrenMix",
    category: ["all", "injection"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 20,
  },
  {
    img: "/images/products/item_turinabol.jpg",
    title: "Turinabol",
    category: ["all", "oral"],
    authorImage: "/images/avatars/avatar_alpha_rounded.png",
    authorName: "Alpha-Pharm",
    id: 21,
  },
];

export const hero_5_data = [
  {
    img: "/images/products/item_16_2lg.jpg",
    title: "Etherium NFT Launching Lab",
    authorImage: "/images/avatars/avatar_18_rounded.gif",
    authorName: "051_Hart",
    id: "0Etherium NFT Launching Lab",
  },
  {
    img: "/images/products/item_12_2lg.jpg",
    title: "Oceania \\\\ OVERSEER 017",
    authorImage: "/images/avatars/avatar_22_rounded.jpg",
    authorName: "MadeByM1KE",
    id: "1Oceania \\\\ OVERSEER 017",

    subItem: [
      {
        img: "/images/products/item_13_lg.jpg",
        title: "Light Bars",
        authorImage: "/images/avatars/avatar_17_rounded.jpg",
        authorName: "Wow Frens",
        id: "2Light Bars",
      },
    ],
  },
];
